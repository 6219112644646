<template>
  <div>
    <!-- Hamburger Icon for toogle menu -->
    <div :class="[headerBackground, 'menu-toggle']" @click="toggleMenu">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        class="bi bi-list"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
        />
      </svg>
    </div>

    <!-- Menu List -->
    <the-side-nav v-model="showMenu" @closeSidebar="showMenu = false">
      <div class="menu-box">
        <div class="navigation_box mains fadeInUp animated">
          <ul>
            <li v-for="nav in navlinks" :key="nav.title">
              <router-link
                :to="nav.path"
                tag="a"
                :exact-active-class="nav.path && 'active-link'"
              >
                {{ nav.title }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </the-side-nav>

    <div :class="headerBackground" class="guest-header">
      <!-- <div class="container"> -->
      <!-- <div class="navigation"> -->
      <div class="logo-box">
        <router-link to="/">
          <img :src="require('@/assets/images/defaults/logo.png')" alt="Logo" />
        </router-link>
      </div>
      <div v-if="socialMedias" class="social-media">
        <a :href="facebook" target="_blank">
          <icon-base>
            <icon-fb />
          </icon-base>
        </a>

        <a :href="twitter" target="_blank">
          <icon-base>
            <icon-twitter />
          </icon-base>
        </a>

        <a :href="linkedIn" target="_blank">
          <icon-base>
            <icon-linkedin />
          </icon-base>
        </a>
      </div>
      <span class="scroll-bottom active"> </span>
      <span class="scroll-top hide">
        <scroll-to-top />
      </span>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
  import { labels, gRoutes } from "@/common";
  import TheSideNav from "@/components/TheSideNav";
  import ScrollToTop from "@/components/ScrollToTop";
  import IconBase from "@/components/IconBase";
  import IconFb from "@/components/icons/IconFb";
  import IconTwitter from "@/components/icons/IconTwitter";
  import IconLinkedin from "@/components/icons/IconLinkedin";

  export default {
    name: "GuestHeader",

    components: {
      TheSideNav,
      ScrollToTop,
      IconBase,
      IconFb,
      IconTwitter,
      IconLinkedin,
    },

    data() {
      return {
        labels,
        navlinks: [...gRoutes],

        company: null,

        scrollPosition: null,
        showMenu: false,
      };
    },

    computed: {
      // phoneNo() {
      //   return this.company && this.company.phone;
      // },

      userHasScrolled() {
        return this.scrollPosition > 100;
      },

      headerBackground() {
        return this.userHasScrolled ? "page-scrolled" : "";
      },

      facebook() {
        return this.company && this.company.facebook_url;
      },

      twitter() {
        return this.company && this.company.twitter_url;
      },

      linkedIn() {
        return this.company && this.company.linkedin_url;
      },

      socialMedias() {
        return this.facebook || this.twitter || this.linkedIn;
      },
    },

    created() {
      this.loadOrgDetails();
    },

    mounted() {
      window.addEventListener("scroll", this.updateOnScroll);
    },

    destroyed() {
      window.removeEventListener("scroll", this.updateOnScroll);
    },

    methods: {
      async loadOrgDetails() {
        this.company = await this.$store.dispatch(
          "organization/getOrganizationDetail"
        );
      },

      updateOnScroll() {
        this.scrollPosition = window.scrollY;
      },

      toggleMenu() {
        this.showMenu ? "" : document.body.classList.add("overflow-hide");
        this.showMenu = !this.showMenu;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .guest-header {
    position: fixed;
    z-index: 9998;
    background: #fff;
    width: 100%;
    top: 0;
    padding: 0;
    left: 0;
    width: 60px;
    height: 100%;
    border-right: 1px solid #ddd;

    .logo-box {
      position: absolute;
      top: 80px;
      left: -66px;
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      transform: rotate(-90deg);

      img {
        height: 50px;
      }
    }
  }

  .guest-header.page-scrolled {
    -webkit-box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.5);
    box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.5);
  }

  // header {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   z-index: 10;

  //   padding: 0 20px 0 calc(50% + 20px);

  //   flex-shrink: 0;

  //   a {
  //     text-decoration: none;
  //     color: inherit;
  //   }

  //   transition: all 200ms ease-out;
  // }

  // .certification {
  //   height: 60px;
  //   width: 150px;
  // }

  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: $header-height;

    &__links {
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;

      & > .navlink {
        margin: 0 1rem;
        padding: 1.5rem 0;
        font-weight: 500;
        letter-spacing: 1px;
      }
    }
  }

  .menu-toggle,
  .menu-close {
    width: 36px;
    height: 36px;
    text-align: center;
    background: #fff;
    line-height: 50px;
    font-size: 16px;
    position: fixed;
    top: 17px;
    right: 10px;
    color: #00544a;
    z-index: 9999;
    cursor: pointer;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    border-radius: 100px;
  }

  .menu-box {
    height: calc(100vh - 50px);
    overflow: auto;
    padding: 0 40px;
  }

  .navigation_box {
    display: flex;
    position: relative;
    margin: 0;
    width: 100%;
  }

  .menu-box .navigation_box {
    padding: 20px 0 30px;
    height: auto;
  }

  .navigation_box ul {
    margin: 0;
    padding: 0;
    width: 100%;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    border-radius: 0 !important;
    border: 0 !important;
    text-align: center;
  }

  .navigation_box.mains ul {
    width: 100%;
    text-align: center;
  }

  .menu-box .navigation_box ul {
    display: block;
    margin: 0;
    padding: 0;
  }

  .menu-box .navigation_box.mains ul {
    text-align: left;
  }

  .navigation_box ul li {
    list-style: none;
    margin: 0 0 0 20px;
    padding: 0;
    display: inline-block;
  }

  .menu-box .navigation_box ul li {
    display: block;
    float: none;
  }

  .menu-box .navigation_box.mains ul li {
    margin-left: 0;
    width: 100%;

    > a {
      color: $primary-color;
      font-weight: 700;
      font-size: 34px;
      padding: 10px 0;
      position: relative;
      display: inline-block;
    }

    > a.active-link {
      color: rgba(235, 141, 34, 0.924);
    }
  }

  .page-scrolled.menu-toggle {
    border-radius: 50%;
    color: WHITE;
    background: $secondary-color;
  }

  .social-media {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .social-media {
    a {
      width: 32px;
      height: 32px;
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      -ms-border-radius: 100px;
      border-radius: 100px;
      border: 1px solid #ccc;
      margin-top: 15px;
      font-size: 18px;
      color: #999;
      line-height: 32px;
      text-align: center;
      display: grid;
      place-content: center;
    }

    svg {
      margin-left: 2px;
    }
  }

  .scroll-bottom,
  .scroll-top {
    position: absolute;
    bottom: 60px;
    left: 12px;
    text-transform: uppercase;
    font-size: 11px;
    // width: 34px;
    // height: 34px;
    overflow: hidden;
    // border: 1px solid $primary-color;
    // -webkit-transform: rotate(90deg);
    // -moz-transform: rotate(90deg);
    // -ms-transform: rotate(90deg);
    // -o-transform: rotate(90deg);
    // transform: rotate(90deg);
  }
</style>
