import axios from "axios";
import router from "@/router/index";
import { Notification } from "element-ui";
import { labels } from "@/common";

const state = {
  albums: null,
  albumDetail: {},
  systemTypes: [],
};

const mutations = {
  SET_ALBUMS: (state, albums) => {
    state.albums = albums;
  },

  SET_ALBUM_DETAIL: (state, detail) => {
    state.albumDetail = detail;
  },

  SET_SYSTEM_TYPES: (state, types) => {
    state.systemTypes = types;
  },
};

const actions = {
  async getSystemTypes({ commit }) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status, data } = await axios.get("system-types");
      if (status === 200) {
        commit("SET_SYSTEM_TYPES", data);
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      } else router.push({ name: "Network Error" });
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async getAlbumDetail({ commit }, id) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status, data } = await axios.get(`albums/${id}`);
      if (status === 200) {
        commit("SET_ALBUM_DETAIL", data.data);
        return data.data;
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        router.push({
          name: "Not Found",
          params: { resource: "project ablum" },
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async getAllAlbums({ commit }, params = { page: 1 }) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status, data } = await axios.get("/albums", { params });
      if (status === 200) {
        commit("SET_ALBUMS", data);
        return data.data;
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      } else router.push({ name: "Network Error" });
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async createAlbum({ commit }, album) {
    commit("SET_BUSY", true, { root: true });
    try {
      const formData = new FormData();
      formData.append("name", album.name);
      formData.append("system_type_id", album.system_type_id);
      formData.append("capacity", album.capacity);
      formData.append("description", album.description);
      formData.append("is_published", album.is_published ? 1 : 0);

      album.album_medias.forEach((media, index) => {
        Object.keys(media).forEach((key) => {
          formData.append(`album_medias[${index}][${key}]`, media[key] ?? "");
        });
      });

      const { status } = await axios.post("/albums", formData);
      if (status === 201) {
        router.push({ name: "CMS Projects" });

        Notification({
          title: labels.LBL_SUCCESS,
          type: "success",
          message: "Album successfully created",
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async updateAlbum({ commit }, updatedAlbum) {
    commit("SET_BUSY", true, { root: true });

    try {
      const formData = new FormData();
      formData.append("name", updatedAlbum.name);
      formData.append("system_type_id", updatedAlbum.system_type_id);
      formData.append("capacity", updatedAlbum.capacity);
      formData.append("description", updatedAlbum.description);
      formData.append("is_published", updatedAlbum.is_published ? 1 : 0);

      if (updatedAlbum.album_medias) {
        updatedAlbum.album_medias.forEach((media, index) => {
          Object.keys(media).forEach((key) => {
            formData.append(`album_medias[${index}][${key}]`, media[key] ?? "");
          });
        });
      }

      if (updatedAlbum.update_album_medias) {
        updatedAlbum.update_album_medias.forEach((media, index) => {
          Object.keys(media).forEach((key) => {
            formData.append(
              `update_album_medias[${index}][${key}]`,
              media[key] ?? ""
            );
          });
        });
      }

      const { status } = await axios.post(
        `/albums/${updatedAlbum.id}`,
        formData
      );

      if (status === 200) {
        router.push({ name: "CMS Projects" });
        Notification({
          title: labels.LBL_SUCCESS,
          type: "success",
          message: "Album successfully updated",
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async deleteAlbum({ commit, dispatch }, id) {
    commit("SET_BUSY", true, { root: true });

    try {
      const { status } = await axios.delete(`/albums/${id}`);
      if (status === 200) {
        dispatch("getAllAlbums");
        Notification({
          title: labels.LBL_SUCCESS,
          type: "success",
          message: "Album successfully deleted",
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async deleteMedia({ commit }, id) {
    commit("SET_BUSY", true, { root: true });

    try {
      const { status } = await axios.delete(`/album-medias/${id}`);
      if (status === 200) {
        Notification({
          title: labels.LBL_SUCCESS,
          type: "success",
          message: "Media successfully deleted",
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },
};

const getters = {
  albumsDDL: (state) =>
    state.albums && state.albums.data.map((a) => ({ id: a.id, name: a.name })),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
