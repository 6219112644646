import axios from "axios";
import { labels } from "@/common";
import { Notification } from "element-ui";
import createFormData from "@/composables/useFormData";
import router from "@/router";

const state = {
  companies: null,
  companyDetail: null,
};

const mutations = {
  SET_COMPANIES: (state, companies) => {
    state.companies = companies;
  },

  SET_COMPANY_DETAIL: (state, detail) => {
    state.companyDetail = detail;
  },
};

const actions = {
  async getCompanyDetail({ commit }, id) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status, data } = await axios.get(`companies/${id}`);
      if (status === 200) {
        commit("SET_COMPANY_DETAIL", data.data);
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        router.push({
          name: "Not Found",
          params: { resource: "company" },
        });
      } else router.push({ name: "Network Error" });
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async getAllCompanies({ commit }, params = { page: 1 }) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status, data } = await axios.get("/companies", {
        params,
      });

      if (status === 200) {
        commit("SET_COMPANIES", data);
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async createCompany({ commit }, company) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.post(
        "/companies",
        createFormData(company)
      );
      if (status === 201) {
        router.push({ name: "Companies" });
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: labels.PH_TOOL_CREATED,
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async updateCompany({ commit }, updatedCompany) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.post(
        `/companies/${updatedCompany.id}`,
        createFormData(updatedCompany)
      );
      if (status === 200) {
        router.push({ name: "Companies" });
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: labels.PH_TOOL_UPDATED,
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async deleteCompany({ commit, dispatch }, id) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.delete(`/companies/${id}`);
      if (status === 200) {
        dispatch("getAllCompanies");
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: labels.PH_TOOL_DELETED,
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
