<template>
  <router-view />
</template>

<script>
  import { mapState } from "vuex";

  export default {
    name: "App",

    computed: {
      ...mapState({
        token: (state) => state.auth.token,
        company: (state) => state.organization.orgDetail,
      }),
    },

    created() {
      this.checkToken();
    },

    methods: {
      checkToken() {
        if (this.token) this.$store.dispatch("auth/checkToken");
      },
    },
  };
</script>

<style lang="scss">
  @import "@/assets/scss/main.scss";

  .overflow-hide {
    overflow: hidden;
    position: relative;
  }

  .container {
    max-width: 1400px;
    margin: 0 auto;
  }

  .el-button {
    &--warning {
      background: $primary-color;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .btn-block {
    width: 100%;
  }

  .primary-heading {
    font-size: 2.125rem;
    font-weight: 600;
    margin: 1rem 0;
    line-height: 2.5rem;
    //letter-spacing: 2px;
    text-transform: capitalize;
    color: $primary-color;
  }

  .secondary-heading {
    font-size: 1.25rem;
    font-weight: 600;
    //letter-spacing: 1px;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    color: $primary-color;
  }

  .caption {
    font-size: 1rem;
  }

  .paragraph {
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.25rem;
  }

  .main-title,
  .para-description {
    color: white;
  }

  .btn-text {
    display: inline-block;
    padding: 0.5rem;
    cursor: pointer;

    transition: all 200ms linear;

    &:hover {
      background: $background-color;
      color: white;
    }
  }

  .text-card {
    padding: 1rem;
    margin-top: 0.5rem;
    background: white;
    border-radius: 0.25rem;
  }

  .default-padding {
    padding: 1.5rem 4rem;

    @media only screen and (max-device-width: 768px) {
      padding: 1.5rem;
    }
  }

  .btn-lg {
    padding: 1rem 1.25rem;
    font-size: 1.125rem;
    text-transform: uppercase;
  }

  .side-header {
    padding-left: 60px;

    // @media only screen and (max-device-width: 768px) {
    //   padding-left: 0;
    // }
  }
</style>
