import axios from "axios";
import { labels } from "@/common";
import { Notification } from "element-ui";
import createFormData from "@/composables/useFormData";
import router from "@/router";

const state = {
  services: null,
  serviceDetail: null,
};

const mutations = {
  SET_SERVICES: (state, services) => {
    state.services = services;
  },

  SET_SERVICE_DETAIL: (state, detail) => {
    state.serviceDetail = detail;
  },
};

const actions = {
  async getServiceDetail({ commit }, id) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status, data } = await axios.get(`services/${id}`);
      if (status === 200) {
        commit("SET_SERVICE_DETAIL", data.data);
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async getAllServices({ commit }, params = {}) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status, data } = await axios.get("/services", { params });
      if (status === 200) {
        commit("SET_SERVICES", data);
        return data;
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async createService({ commit }, service) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.post("/services", createFormData(service));
      if (status === 201) {
        router.push({ name: "CMS Services" });
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: "Service successfully created",
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async updateService({ commit }, updatedService) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.post(
        `/services/${updatedService.id}`,
        createFormData(updatedService)
      );
      if (status === 200) {
        router.push({ name: "CMS Services" });
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: "Service successfully updated",
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async deleteService({ commit, dispatch }, id) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status } = await axios.delete(`/services/${id}`);
      if (status === 200) {
        dispatch("getAllServices", { limit: 100 });
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: "Service successfully deleted",
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },
};

const getters = {
  servicesDDL: (state) =>
    state.services && state.services.data.map((s) => s.name),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
