const labels = {
  COMPANY_NAME: "Softweb Developers",
  COMPANY_PHONE: "+977 9841 508740",
  COMPANY_EMAIL: "info@softwebdevelopers.com",
  COMPANY_ADDRESS: "Hetauda, Makawanpur",
  COMPANY_ADDRESS_2: "Bagamati, Nepal",
  COMPANY_POSTAL: "44100",

  DEV_NAME: "Softweb Developers",
  DEV_WEBSITE: "https://softwebdevelopers.com",

  // Menus
  MENU_HOME: "Home",
  MENU_ABOUT: "About",
  MENU_EXPERTISE: "Services",
  MENU_TEAMS: "Team",
  MENU_NEWS: "Blog",
  MENU_CONTACT: "Contact",
  MENU_PROJECTS: "Projects",

  //Labels
  LBL_LOGIN: "Login",
  LBL_LOGOUT: "Logout",
  LBL_FORGOT_PASSWORD: "Forgot Password",
  LBL_RESET_PASSWORD: "Reset Password",
  LBL_CHANGE_PASSWORD: "Change Password",
  LBL_CURRENT_PASSWORD: "Current Password",
  LBL_NEW_PASSWORD: "New Password",
  LBL_CONFIRM_PASSWORD: "Confirm Password",

  LBL_LOADING: "Loading",
  LBL_SENDING: "Sending",
  LBL_LOGGING_IN: "Logging In",
  LBL_LOGGING_OUT: "Logging Out",
  LBL_SUBSRCRIBING: "Subscribing",

  LBL_SUCCESS: "Success",
  LBL_ERROR: "Error",
  LBL_INFO: "Info",
  LBL_WARN: "Warning",
  LBL_OK: "Okay",
  LBL_YES: "Yes",
  LBL_CANCEL: "Cancel",
  LBL_NEW: "New",
  LBL_ADD: "Add",
  LBL_SAVE: "Save",
  LBL_UPDATE: "Update",
  LBL_EDIT: "Edit",
  LBL_NO_DATA: "No Data Found",
  LBL_FROM: "From",
  LBL_TO: "To",
  LBL_DATE: "Date",
  LBL_CONTENT: "Content",
  LBL_PHOTO: "Photo",
  LBL_DESIGNATION: "Designation",
  LBL_GRANT: "Grant",
  LBL_REVOKE: "Revoke",
  LBL_SALUTATIONS: "Salutations",
  LBL_ABOUT: "About",
  LBL_LOGO: "Logo",
  LBL_BANNERS: "Banners",
  LBL_SERVICES: "Services",

  LBL_LOGIN_ACCESS: "Login Access",
  LBL_SES_EXP: "Session Expired",
  LBL_ACTIVITY_LOGS: "Activity Logs",
  LBL_DESCRIPTION: "Description",
  LBL_PUBLISH_ALBUM: "Publish Album",
  LBL_PUBLISH_NEWS: "Publish Blog",
  LBL_PUBLISH_BANNER: "Publish Banner",
  LBL_TEAM_MEMBERS: "Team Members",
  LBL_QUICK_CONTACT: "Quick Contact",
  LBL_CALL_US: "Call Us",
  LBL_EMAIL_US: "Email Us",
  LBL_CLIENTS: "Happy clients",
  LBL_PROJECT_DELIVERED: "Projects delivered",

  LBL_SERVICE_MAIN_TITLE: "Services We Provide",

  LBL_SUBSCRIBE: "Subscribe",
  LBL_SUBSCRIBERS: "Subscribers",
  LBL_SUBSCRIBED_ON: "Subscribed on",
  LBL_INTERESTED_IN: "Interested In",

  LBL_ALL_RIGHTS: "All rights reserved",
  LBL_COPY_RIGHT: "© Copyright.",
  LBL_DESIGN_DEVELOP: "Designed and Developed by",
  LBL_SUSCRIBE_FOR_UPDATES: "Subscribe for latest updates",

  LBL_TITLE: "Title",
  LBL_SUBTITLE: "Subtitle",
  LBL_EDITOR: "Editor",

  LBL_URL: "Url",
  LBL_IMAGES: "Images",
  LBL_VIDEOS: "Videos",

  LBL_NAME: "Name",
  LBL_FIRST_NAME: "First Name",
  LBL_LAST_NAME: "Last Name",
  LBL_FULL_NAME: "Full Name",
  LBL_EMAIL: "Email Address",
  LBL_CONTACT_NO: "Contact Number",
  LBL_ADDRESS: "Address",
  LBL_ADDRESS_2: "Address 2",
  LBL_CITY: "City",
  LBL_STATE: "State",
  LBL_POSTAL: "Postal Code",
  LBL_COM_URL: "Company Url",
  LBL_COM_MOTTO: "Company Motto",
  LBL_HIGHEST_EDU: "Highest Education",

  LBL_SEND_MAIL: "Send Mail",
  LBL_CREATED: "Created on",
  LBL_PUBLISHED_ON: "Published on",
  LBL_STATUS: "Status",
  LBL_PUBLISHED: "Published",
  LBL_UNPUBLISHED: "Unpublished",
  LBL_REPLIED: "Replied",
  LBL_UNREPLIED: "Unreplied",
  LBL_TYPE_2_SEARCH: "Type to search",

  LBL_MESSAGE: "Message",
  LBL_REPLY: "Reply",
  LBL_SEND: "Send",
  LBL_FEEDBACK: "Feedback",
  LBL_OFFICE: "Office",
  LBL_DIRECT_CONTACT: "Direct Contact",
  LBL_WELCOME: "Welcome Back",
  LBL_RECENT_NEWS: "Recent Blog",
  LBL_FOLLOW_US: "Follow Us",

  LBL_LOAD_MORE: "Load More",
  LBL_SEE_MORE: "See More",
  LBL_LEARN_MORE: "Learn More",
  LBL_READ_MORE: "Read More",

  LBL_TESTIMONIALS: "Read Clients Success Stories",
  LBL_INSTALLATION: "Installation",
  LBL_DES_ENG: "Design and Engineering",
  LBL_SALES_ANALYSIS: "Sales and Analysis",
  LBL_MAINTENANCE: "Maintenance",
  LBL_FINANCE: "Finance",
  LBL_VISION: "Our Vision",

  LBL_PROJECT_ALBUMS: "Project Albums",

  LBL_PROJECTS: "Projects",
  LBL_COMPANY_NAME: "Company Name",
  LBL_COMPANY_DETAIL: "Company Detail",
  LBL_ESTD_DATE: "Established Date",

  LBL_SOCIAL_LINKS: "Social Media Links",
  LBL_FB: "Facebook",
  LBL_YT: "Youtube",
  LBL_LNKIN: "LinkedIn",
  LBL_TWI: "Twitter",
  LBL_INSTA: "Instagram",

  LBL_TOOLS_AND_TECH: "Tools and Technologies",
  LBL_SOFTWARE_DEVELOPMENT: "Software Applications",
  LBL_MOBILE_APPLICATION_DEVELOPMENT: "Mobile App Development",
  LBL_DATA_ANALYTICS: "Data Analytics",
  LBL_IT_CONSULTING: "IT Consulting",
  LBL_WEB_APP_DEVELOPMENT: "Web App Development",
  LBL_DIGITAL_MARKETING: "Digital Marketing",

  //Placeholders
  PH_HOME_QUOTE: "Making energy eco-friendly for you, me and future",
  PH_ABOUT_QUOTE:
    "Since 2016. We transform businesses with powerful and adaptable digital solutions that satisfy the needs of today and unlock the opportunities of tomorrow.",
  PH_SERVICES_QUOTE: "We are best at what we do",
  PH_PROJECTS_QUOTE:
    "Talent wins games, but teamwork and intelligence wins championships.",
  PH_NEWS_QUOTE: "Get updated about what's happening in SoftWeb",
  PH_TEAM_QUOTE:
    " Great things in business are never done by one person. They're done by a team of people.",
  PH_TEAM_QUOTE_2:
    "The people behind our work are the most important part of what we do at SoftWeb.",

  PH_IN_TOUCH: "Got a Challenge?",
  PH_IN_TOUCH_CAP: "Tell us about your business problem to start working on a solution with top software development experts.",
  PH_FIND_US: "How to Find Us",
  PH_START_CONVERS: "Let's start a conversation",

  PH_VISIT_US: "Give us a visit",
  PH_CUSTOMER_SUPPORT: "For supports or any questions",
  PH_MEET_TEAM: "Meet the Team",
  PH_PROJECT_DONE: "Projects we have done so far",
  PH_NEWS: "Softweb Blog",
  PH_NEWS_UPDATE: "Blog updates for you",
  PH_LOGIN: "Login and lets get started!",
  PH_TESTIMONIALS: "Empowering our clients to succeed with technologies.",
  PH_SERVICES:
    "We pride ourselves on offering a bespoke, end-to-end experience for our customers to ensure the upmost quality, accelerated delivery, and peace of mind.",
  PH_SOFTWARE_DEVELOPMENT:
    "Flexible and scalable solutions of software (web based or desktop based) to meet the needs for small or large firm.",
  PH_MOBILE_APPLICATION_DEVELOPMENT:
    "Delivering on-demand mobile app experiences for startups and enterprise clients by leveraging the latest technologies.",
  PH_DATA_ANALYTICS:
    "BI programs can also incorporate forms of advanced analytics, such as data mining, predictive analytics, text mining, statistical analysis and big data analytics.",
   PH_IT_CONSULTING:
    "Information technology (IT) consulting services allow companies to implement IT strategies and solutions to achieve business-IT alignment, and drive max value from the current IT initiatives.",
  PH_WEB_APP_DEVELOPMENT:
    "A professional website design and development with complete solutions for all kinds of businesses along with best web hosting services.",
  PH_DIGITAL_MARKETING:
    "Promotion of brands to connect with potential customers using the internet and other forms of digital communication.",
  PH_TOOLS_AND_TECH:
    "Our expertise spans all major technologies and platforms, and advances to innovative technology trends.",

  PH_FEEDBACK: "Please enter your feedback ",
  PH_EMAIL: "Please enter email",
  PH_NAME: "Please enter name",
  PH_PASSWORD: "Please enter password",
  PH_NEW_PASSWORD: "Please enter new password",
  PH_FORGOT_PASSWORD:
    "Enter the email address you used when you joined and we'll send you instructions to reset your password.",
  PH_CONFIRM_PASSWORD: "Please confirm your password",
  PH_DESCRIPTION: "Please enter description",
  PH_TITLE: "Please enter title",
  PH_URL: "Please enter url",
  PH_REPLY: "Please enter message content",
  PH_EDITOR: "Please enter editor's name",
  PH_CONTENT: "Please enter content",
  PH_SELECT_DESIGNATION: "Select designation",

  PH_LOGIN_SUCECSS: "Logged in successfully",
  PH_LOGOUT_SUCECSS: "Logged out successfully",
  PH_PASS_CHANGE_SUCCESS: "Password successfully changed",
  PH_REDIRECTED: "You will be redirected to the login page.",
  PH_WARN_LOGOUT: "Are you sure you want to logout?",
  PH_RESET_LINK_SENT: "Reset Link sent successfully. Please check your email.",
  PH_FEEDBACK_SUBMITTED: "Feedback successfully submitted",
  PH_FEEDBACK_REPLIED: "Feedback successfully replied",
  PH_FEEDBACK_DELETED: "Feedback successfully deleted",
  PH_SUBSCRIBE_SUCCESS:
    "You have been successfully subscribed to our news letter",
  PH_SUBSCRIBER_DELETED: "Subscriber successfully deleted",
  PH_COM_DETAIL_UPDATED: "Company details successfully updated",
  PH_NEWS_CREATED: "Blog successfully created",
  PH_NEWS_UPDATED: "Blog successfully updated",
  PH_NEWS_DELETED: "Blog successfully deleted",
  PH_NEWS_IMAGE_DELETED: "Blog Image successfully deleted",
  PH_MEMBER_SUCCESS: "Team member successfully created",
  PH_MEMBER_UPDATED: "Team member successfully updated",
  PH_MEMBER_DELETED: "Team member successfully deleted",
  PH_LOGIN_STATUS_UPDATED: "Team member's login access status updated",
  PH_TOOL_CREATED: "Tool successfully created",
  PH_TOOL_UPDATED: "Tool successfully updated",
  PH_TOOL_DELETED: "Tool successfully deleted",

  PH_ALBUMS_NF: "No Projects found",
  PH_NEWS_NF: "No Blog found",
  PH_MEMBERS_NF: "No Members Found",
  PH_TOOLS_NF: "No Tools found",
  PH_TESTIMONIAL_NF: "No Testimonials found",

  //Error Messages
  ER_EMAIL: "Invalid Email Format",
  ER_PASSWORD_LENGTH: "Password length should be atleast 8",
  ER_PASSWORDS_MATCH: "Passwords donot match",
  ER_CPASSWORD_REQUIRED: "Confirm password is required",
  ER_INVALID_DATA: "The given data was invalid",
  ER_LENGTH_5: "Length should be atleast 5",
  ER_LENGTH_10: "Length should be atleast 10",
  ER_LENGTH_20: "Length should be atleast 20",

  //Confirmations
  CONF_DELETE: "Are you sure you want to delete",
  CONF_UPDATE: "Are you sure you want to update",
  CNCL_DELETE: "Deletion Cancelled",
  CNCL_ACCESS: "Access Cancelled",
};

export default labels;
