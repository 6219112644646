import labels from "./labels";

const minNameLength = 5;
const minPasswordLength = 8;
const minDescripLength = 20;

let rules = {
  email: [
    {
      required: true,
      email: true,
      message: labels.PH_EMAIL,
      trigger: "blur",
    },
    {
      required: true,
      pattern: /\S+@\S+\.\S+/,
      message: labels.ER_EMAIL,
      trigger: "blur",
    },
  ],
  password: [
    {
      required: true,
      message: labels.PH_PASSWORD,
      trigger: "blur",
    },
    {
      min: minPasswordLength,
      message: labels.ER_PASSWORD_LENGTH,
      trigger: "blur",
    },
  ],
  current_password: [
    {
      required: true,
      message: labels.PH_PASSWORD,
      trigger: "blur",
    },
  ],
  new_password: [
    {
      required: true,
      message: labels.PH_NEW_PASSWORD,
      trigger: "blur",
    },
  ],
  name: [
    {
      required: true,
      message: labels.PH_NAME,
      trigger: "blur",
    },
  ],
  message: [
    {
      required: true,
      message: labels.PH_FEEDBACK,
      trigger: "blur",
    },
  ],
  description: [
    {
      required: true,
      message: labels.PH_DESCRIPTION,
      trigger: "blur",
    },
    {
      min: minDescripLength,
      message: labels.ER_LENGTH_20,
      trigger: "blur",
    },
  ],
  title: [
    {
      required: true,
      message: labels.PH_TITLE,
      trigger: "blur",
    },
    {
      min: minNameLength,
      message: labels.ER_LENGTH_5,
      trigger: "blur",
    },
  ],
  url: [
    {
      required: true,
      message: labels.PH_URL,
      trigger: "blur",
    },
  ],
  reply: [
    {
      required: true,
      message: labels.PH_REPLY,
      trigger: "change",
    },
  ],
  editor: [
    {
      required: true,
      message: labels.PH_EDITOR,
      trigger: "blur",
    },
  ],
  content: [
    {
      required: true,
      message: labels.PH_CONTENT,
      trigger: "change",
    },
  ],
  establishment: [
    {
      required: true,
      message: "Please input organization establishment date",
      trigger: "blur",
    },
  ],
  phone: [
    {
      required: true,
      message: "Please input contact number",
      trigger: "blur",
    },
  ],
  address1: [
    {
      required: true,
      message: "Please input address",
      trigger: "blur",
    },
  ],
  city: [{ required: true, message: "Please input city", trigger: "blur" }],
  state: [{ required: true, message: "Please input state", trigger: "blur" }],
  postal: [{ required: true, message: "Please input postal", trigger: "blur" }],
  motto: [
    {
      required: true,
      message: "Please input organization motto",
      trigger: "blur",
    },
  ],

  online_link: [
    {
      required: true,
      message: "Please input website link",
      trigger: "blur",
    },
  ],

  salutation: [
    {
      required: true,
      message: "Please select a salutation",
      trigger: "blur",
    },
  ],
  first_name: [
    {
      required: true,
      message: "Please input first name",
      trigger: "blur",
    },
  ],
  last_name: [
    {
      required: true,
      message: "Please input last name",
      trigger: "blur",
    },
  ],
  address: [
    {
      required: true,
      message: "Please input address",
      trigger: "blur",
    },
  ],
  voice: [
    {
      required: true,
      message: "Please input short detail about member",
      trigger: "blur",
    },
    {
      min: 100,
      message: "Length should be alleast 100",
      trigger: "blur",
    },
  ],
  designation_id: [
    {
      required: true,
      message: "Please select designation",
      trigger: "change",
    },
  ],
  highest_education: [
    {
      required: true,
      message: "Please input highest education",
      trigger: "blur",
    },
  ],
  interested_in: [
    {
      required: true,
      message: "Please select your interest",
      trigger: "change",
    },
  ],
  image: [
    {
      required: true,
      message: "Please select an image",
      trigger: "change",
    },
  ],
  system_type_id: [
    {
      required: true,
      message: "Please select system type",
      trigger: "change",
    },
  ],
  capacity: [
    {
      required: true,
      message: "Please enter system capacity",
      trigger: "blur",
    },
  ],
  logo: [
    {
      required: true,
      message: "Please select a logo",
      trigger: "blur",
    },
  ],
  client_name: [
    {
      required: true,
      message: "Please enter name",
      trigger: "blur",
    },
  ],
  client_business: [
    {
      required: true,
      message: "Please enter business",
      trigger: "blur",
    },
  ],
  testimonial: [
    {
      required: true,
      message: "Please enter testimonial",
      trigger: "blur",
    },
  ],
};

export default rules;
