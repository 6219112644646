import { labels } from "@/common";

const services = [
  {
    image: "coding.png",
    name: labels.LBL_SOFTWARE_DEVELOPMENT,
    scrollTo: "#1",
    description: labels.PH_SOFTWARE_DEVELOPMENT,
  },
  {
    image: "mobile-app.png",
    name: labels.LBL_MOBILE_APPLICATION_DEVELOPMENT,
    scrollTo: "#2",
    description: labels.PH_MOBILE_APPLICATION_DEVELOPMENT,
  },
  {
    image: "artificial-intelligence.png",
    name: labels.LBL_DATA_ANALYTICS,
    scrollTo: "#3",
    description: labels.PH_DATA_ANALYTICS,
  },
  {
    image: "data-storage.png",
    name: labels.LBL_IT_CONSULTING,
    scrollTo: "#4",
    description: labels.PH_IT_CONSULTING,
  },
  {
    image: "web-app.png",
    name: labels.LBL_WEB_APP_DEVELOPMENT,
    scrollTo: "#5",
    description: labels.PH_WEB_APP_DEVELOPMENT,
  },
  {
    image: "digital-marketting.png",
    name: labels.LBL_DIGITAL_MARKETING,
    scrollTo: "#6",
    description: labels.PH_DIGITAL_MARKETING,
  },
];

export default services;
