<template>
  <div class="side-nav" :class="navClass" @click="closeNav">
    <div class="side-nav__links">
      <div class="close-box" @click="closeNav">
        <span class="main-title">
          <span class="menu-close">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-x-lg"
              viewBox="0 0 16 16"
            >
              <path
                d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"
              />
            </svg>
          </span>
        </span>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: "SideNavigation",

    props: {
      value: { type: Boolean },
    },

    computed: {
      navClass() {
        return this.value ? "side-nav--open" : "";
      },
    },

    methods: {
      closeNav() {
        document.body.classList.remove('overflow-hide');
        this.$emit("closeSidebar");
      },
    },
  };
</script>

<style lang="scss">
  .side-nav {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
    color: black;
    z-index: 9999;
    display: none;
    transition: all 2s ease-in;

    &__links {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;

      width: 350px;
      background-color: white;
      display: flex;
      flex-direction: column;

      animation: movebg 400ms ease-in-out;

    }

    &--open {
      display: block;
    }
  }

  .close-box {
    background: #fff;
    position: relative;
    top: 0;
    height: 50px;
    padding-left: 20px;
    width: 100%;
    line-height: 50px;

    .main-title {
      font-size: 16px;
      color: #00544a;
      display: block;
      text-transform: uppercase;
    }
  }

  .menu-close {
    background: 0 0;
    position: absolute;
    top: 5px;
    right: 30px;
    color: #222;

    width: 36px;
    height: 36px;
    text-align: center;
    line-height: 50px;
    font-size: 16px;
    z-index: 9999;
    cursor: pointer;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    border-radius: 100px;
}

  // .close-box {
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: flex-start;

  //   padding: 0.5rem 1rem 0 1rem;

  //   svg {
  //     cursor: pointer;
  //     margin-top: 1rem;
  //   }
  // }

  // .app-logo {
  //   height: 60px;
  //   width: 70px;
  //   margin-top: -0.5rem;
  // }
</style>
