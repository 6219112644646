import Vue from "vue";
import Vuex from "vuex";
// import createPersistedState from "vuex-persistedstate";

import auth from "./modules/auth";
import album from "./modules/album";
import feedback from "./modules/feedback";
import subscription from "./modules/subscription";
import organization from "./modules/organization";
import news from "./modules/news";
import executive from "./modules/executive";
import company from "./modules/company";
import banner from "./modules/banner";
import expertise from "./modules/expertise";
import testimonial from "./modules/testimonial";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isBusy: false,
  },

  mutations: {
    SET_BUSY: (state, busy) => {
      state.isBusy = busy;
    },
  },

  modules: {
    auth,
    album,
    feedback,
    subscription,
    organization,
    news,
    executive,
    company,
    banner,
    expertise,
    testimonial,
  },

  // plugins: [
  //   createPersistedState({
  //     storage: window.sessionStorage,
  //     paths: ["organization"],
  //   }),
  // ],
});
