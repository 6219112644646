<template>
  <div class="footer side-header">
    <div class="container default-padding content-wrapper">
      <el-row :gutter="20">
        <el-col :span="24" :lg="6" class="mb-4">
          <div class="mb-2 footer-menu">
            Explore
          </div>

          <router-link
            v-for="route in gRoutes"
            :key="route.title"
            :to="route.path"
            class="footer-submenu"
          >
            {{ route.title }}
          </router-link>
        </el-col>

        <el-col :span="24" :lg="12">
          <div v-if="phoneNo || email" class="mb-2 footer-menu">
            {{ labels.MENU_CONTACT }} Us
          </div>
          <el-row>
            <el-col :span="24" :lg="12">
              <div class="item-box">
                <h2 class="item-box-title">
                  HEAD OFFICE
                  <div class="flag-box">
                  <img
                    :src="require('@/assets/images/defaults/flag-np.png')"
                    alt="Nepal"
                    class="flag-np"
                  />
                  </div>
                </h2>
                <span class="footer-submenu">
                  SoftWeb Developers
                  <br />
                  Hetauda, Makawanpur
                  <br />
                  Bagamati, Nepal
                </span>

                <div v-if="phoneNo" class="footer-submenu">
                  <icon-base class="mr-1" style="color: '#495f7e'">
                    <icon-phone-filled />
                  </icon-base>

                  <a :href="`tel:${phoneNo}`">
                    {{ phoneNo }}
                  </a>
                </div>

                <div v-if="email" class="footer-submenu">
                  <icon-base class="mr-1" style="color: '#495f7e'">
                    <icon-mail-filled />
                  </icon-base>

                  <a :href="`mailto:${email}`">
                    {{ email }}
                  </a>
                </div>
              </div>
            </el-col>

            <el-col :span="24" :lg="12">
              <div class="item-box">
                <h2 class="item-box-title">
                  BRANCH OFFICE
                  <div class="flag-box">
                  <img
                    :src="require('@/assets/images/defaults/flag-ca.png')"
                    alt="Canada"
                    class="flag-ca"
                  />
                  </div>
                </h2>
                <span class="footer-submenu">
                  SoftWeb Developers
                  <br />
                  #15 Manorwood Rd, Scarborough
                  <br />
                  Toronto, Ontario
                  <br />
                  Canada, M1P 4G6
                </span>

                <div v-if="phoneNo" class="footer-submenu">
                  <icon-base class="mr-1" style="color: '#495f7e'">
                    <icon-phone-filled />
                  </icon-base>

                  <a :href="`tel:${phoneNo}`">
                    +1 (416) 616-2685
                  </a>
                </div>

                <div v-if="email" class="footer-submenu">
                  <icon-base class="mr-1" style="color: '#495f7e'">
                    <icon-mail-filled />
                  </icon-base>

                  <a :href="`mailto:${email}`">
                    {{ email }}
                  </a>
                </div>
              </div>
            </el-col>

            <!-- <el-col :span="24" :lg="12">
              <div class="item-box">
                <h2 class="item-box-title">
                  BRANCH OFFICE
                  <div class="flag-box">
                    <img
                      :src="require('@/assets/images/defaults/flag-tnt.png')"
                      alt="Trinidad & Tobago"
                      class="flag-tt"
                    />
                  </div>
                </h2>
                <span class="footer-submenu">
                  SoftWeb Developers
                  <br />
                  #234 Diamond Village, Papourie Road
                  <br />
                  Sanfernando, Trinidad & Tobago
                  <br />
                  Westindies
                </span>

                <div v-if="phoneNo" class="footer-submenu">
                  <icon-base class="mr-1" style="color: '#495f7e'">
                    <icon-phone-filled />
                  </icon-base>

                  <a :href="`tel:${phoneNo}`">
                    +1 (868) 340-3081
                  </a>
                </div>

                <div v-if="email" class="footer-submenu">
                  <icon-base class="mr-1" style="color: '#495f7e'">
                    <icon-mail-filled />
                  </icon-base>

                  <a :href="`mailto:${email}`">
                    {{ email }}
                  </a>
                </div>
              </div>
            </el-col> -->
          </el-row>
        </el-col>

        <el-col :span="24" :lg="6">
          <div v-if="socialMedias">
            <div class="footer-menu mb-2">
              {{ labels.LBL_FOLLOW_US }}
            </div>

            <div class="social-medias">
              <a v-if="facebook" :href="facebook" class="">
                <icon-base>
                  <icon-fb />
                </icon-base>
              </a>

              <a v-if="twitter" :href="twitter">
                <icon-base>
                  <icon-twitter />
                </icon-base>
              </a>

              <a v-if="linkedIn" :href="linkedIn">
                <icon-base>
                  <icon-linkedin />
                </icon-base>
              </a>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <hr />

    <div align="center" class="my-4 container">
      <div class="copy-rights">
        {{ labels.LBL_COPY_RIGHT }} 2016 - {{ currentYear }}
        {{ labels.DEV_NAME }}.
        {{ labels.LBL_ALL_RIGHTS }}
      </div>

      <!-- <div class="design-develop">
        {{ labels.LBL_DESIGN_DEVELOP }}
        <a :href="labels.DEV_WEBSITE" target="_blank">
          {{ labels.DEV_NAME }}
        </a>
      </div> -->
    </div>
  </div>
</template>

<script>
  import { labels, gRoutes } from "@/common";
  import IconBase from "@/components/IconBase";
  import IconFb from "@/components/icons/IconFb";
  import IconTwitter from "@/components/icons/IconTwitter";
  import IconLinkedin from "@/components/icons/IconLinkedin";
  import IconPhoneFilled from "@/components/icons/IconPhoneFilled";
  import IconMailFilled from "@/components/icons/IconMailFilled";

  export default {
    name: "AppFooter",

    components: {
      IconBase,
      IconFb,
      IconTwitter,
      IconLinkedin,
      IconPhoneFilled,
      IconMailFilled,
    },

    data() {
      return {
        labels,
        gRoutes,

        company: null,
      };
    },

    computed: {
      email() {
        return this.company && this.company.email;
      },

      phoneNo() {
        return this.company && this.company.phone;
      },

      facebook() {
        return this.company && this.company.facebook_url;
      },

      twitter() {
        return this.company && this.company.twitter_url;
      },

      linkedIn() {
        return this.company && this.company.linkedin_url;
      },

      socialMedias() {
        return this.facebook || this.twitter || this.linkedIn;
      },

      currentYear() {
        return new Date().getFullYear();
      },
    },

    created() {
      this.loadOrgDetails();
    },

    methods: {
      async loadOrgDetails() {
        this.company = await this.$store.dispatch(
          "organization/getOrganizationDetail"
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .footer {
    width: 100%;
    box-sizing: border-box;
    background: $tertiary-color;
    color: white;

    min-height: 400px;
    padding-top: 100px;
    font-size: 0.9rem;

    .content-wrapper {
      .footer-menu {
        font-size: 1.375rem;
        color: #fff;
        margin-bottom: 15px;
        font-weight: 700;
        display: block;
      }

      .item-box {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;

        .item-box-title {
          color: #edf8f8;
          font-size: 14px;
          font-weight: 700;
          line-height: 22px;
          text-transform: uppercase;
          display: flex;

          .flag-box {
            width: 30px;
            height: 36px;
            margin-left: 10px;
            position: relative;
            top: -5px;
          }
        }


        .flag-np,
        .flag-ca,
        .flag-tt {
          height: 36px;
          width: 30px;
        }
      }

      .footer-submenu {
        display: flex;
        align-items: center;
        color: lightgray;
        transition: all 200ms ease;
        font-size: 16px;
        display: block;
        line-height: 31px;

        svg {
          color: $primary-color;
          flex-shrink: 0;
        }

        &:hover {
          border-left: 1px solid white;
          padding-left: 1rem;
          color: lighten($color: $primary-color, $amount: 20);
        }
      }
    }
  }

  .social-medias {
    a {
      &:not(:first-child, :last-child) {
        margin: 0 0.75rem;
      }

      svg {
        height: 23px;
        width: 23px;
        flex-shrink: 0;
        margin-top: 4px;
      }
    }
  }

  .copy-rights,
  .design-develop {
    font-size: 0.8rem;
    letter-spacing: 1px;
  }

  .design-develop {
    margin-top: 0.5rem;
    a {
      text-decoration: none;
      color: $primary-color;
    }
  }

  @media only screen and (max-device-width: 768px) {
    .footer {
      padding-top: 0;
      .content-wrapper {
        .footer-menu,
        .item-box {
          padding-top: 30px;
        }
      }
    }


  }
</style>
