import axios from "axios";
import router from "@/router/index";
import { labels } from "@/common";
import { Notification } from "element-ui";
import createFormData from "@/composables/useFormData";

const state = {
  news: null,
  newsDetail: {},
};

const mutations = {
  SET_NEWS: (state, news) => {
    state.news = news;
  },
  SET_NEWS_DETAIL: (state, detail) => {
    state.newsDetail = detail;
  },
};

const actions = {
  async getNewsDetail({ commit }, id) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status, data } = await axios.get(`news/${id}`);
      if (status === 200) {
        commit("SET_NEWS_DETAIL", data.data);
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        router.push({
          name: "Not Found",
          params: { resource: "blog" },
        });
      } else router.push({ name: "Network Error" });
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async getAllNews({ commit }, params = { page: 1 }) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status, data } = await axios.get("/news", { params });
      if (status === 200) {
        commit("SET_NEWS", data);
        return data;
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      } else router.push({ name: "Network Error" });
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async createNews({ commit }, news) {
    commit("SET_BUSY", true, { root: true });

    const formData = createFormData(news);

    news.images.forEach((img, index) => {
      formData.append(`images[${index}]`, img);
    });

    try {
      const { status } = await axios.post("/news", formData);
      if (status === 201) {
        router.push({ name: "CMS Blogs" });
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: labels.PH_NEWS_CREATED,
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async updateNews({ commit }, updatedNews) {
    commit("SET_BUSY", true, { root: true });

    const formData = createFormData(updatedNews);

    updatedNews.images.forEach((img, index) => {
      formData.append(`images[${index}]`, img);
    });

    try {
      const { status } = await axios.post(`/news/${updatedNews.id}`, formData);
      if (status === 200) {
        router.push({ name: "CMS Blogs" });
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: labels.PH_NEWS_UPDATED,
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async deleteNews({ commit, dispatch }, id) {
    commit("SET_BUSY", true, { root: true });

    try {
      const { status } = await axios.delete(`/news/${id}`);
      if (status === 200) {
        dispatch("getAllNews");
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: labels.PH_NEWS_DELETED,
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async deleteImages({ commit }, images) {
    commit("SET_BUSY", true, { root: true });

    try {
      const { status } = await axios.delete("/news-images", {
        data: images,
      });
      if (status === 200) {
        Notification({
          title: labels.LBL_SUCCESS,
          type: labels.LBL_SUCCESS.toLowerCase(),
          message: labels.PH_NEWS_IMAGE_DELETED,
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.LBL_ERROR,
          type: labels.LBL_ERROR.toLowerCase(),
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
