<template>
  <div class="admin-header">
    <router-link to="/" custom v-slot="{ navigate }">
      <img
        :src="require('@/assets/images/defaults/logo.png')"
        alt="Logo"
        style="height: 50px; width: 200px"
        role="link"
        @click="navigate"
        @keypress.enter="navigate"
      />
    </router-link>

    <el-dropdown v-if="loggedIn">
      <span class="el-dropdown-link">
        <el-avatar
          size="medium"
          :src="
            userDetail.photo_url
              ? userDetail.photo_url
              : require('@/assets/images/defaults/user.png')
          "
          fit="contain"
          style="cursor: pointer"
        ></el-avatar>
      </span>

      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item disabled>
          <div>
            <i class="el-icon-user"> </i>
            {{ fullName }}
          </div>
          <div>
            <i class="el-icon-message"> </i>
            {{ userDetail.email }}
          </div>
        </el-dropdown-item>

        <el-dropdown-item disabled>
          <span>
            <i class="el-icon-tickets"> </i>
            {{ labels.LBL_ACTIVITY_LOGS }}
          </span>
        </el-dropdown-item>

        <el-dropdown-item>
          <span @click="changePassword">
            <i class="el-icon-key"> </i>
            {{ labels.LBL_CHANGE_PASSWORD }}
          </span>
        </el-dropdown-item>

        <el-dropdown-item divided>
          <span @click="logout">
            <i class="el-icon-switch-button"></i>
            {{ labels.LBL_LOGOUT }}
          </span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
  import { labels } from "@/common";
  import { mapState, mapGetters } from "vuex";

  export default {
    name: "AdminHeader",

    data() {
      return {
        labels,
      };
    },

    computed: {
      ...mapState({
        currentUser: (state) => state.auth.loggedInUser,
      }),

      ...mapGetters({ loggedIn: "auth/loggedIn", isAdmin: "auth/isAdmin" }),

      userDetail() {
        return this.currentUser.executive
          ? this.currentUser.executive
          : this.currentUser;
      },

      fullName() {
        return this.userDetail.first_name
          ? `${this.userDetail.first_name} ${this.userDetail.last_name}`
          : this.userDetail.name;
      },
    },

    methods: {
      logout() {
        this.$confirm(labels.PH_WARN_LOGOUT, labels.LBL_WARN, {
          confirmButtonText: labels.LBL_OK,
          cancelButtonText: labels.LBL_CANCEL,
          type: labels.LBL_WARN.toLowerCase(),
        }).then(() => {
          this.$store.dispatch("auth/logout");
        });
      },

      changePassword() {
        this.$router.push({ name: "Change Password" });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .admin-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background: white;

    height: $header-height;
    padding: 0 1.5rem;
    box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.295);
  }
</style>
