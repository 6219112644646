<template>
  <a v-show="isVisible" @click="scrollTop" class="bottom-right">
    <div class="up">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        class="bi bi-chevron-up bounce"
        viewBox="0 0 16 16"
        style="--order: 1"
      >
        <path
          fill-rule="evenodd"
          d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
        />
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        class="bi bi-chevron-up bounce"
        viewBox="0 0 16 16"
        style="--order: 2"
      >
        <path
          fill-rule="evenodd"
          d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
        />
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        class="bi bi-chevron-up bounce"
        viewBox="0 0 16 16"
        style="-- order: 3"
      >
        <path
          fill-rule="evenodd"
          d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
        />
      </svg>
    </div>
  </a>
</template>

<script>
  export default {
    name: "ScrollToTop",

    data() {
      return {
        isVisible: false,
      };
    },

    mounted() {
      window.addEventListener("scroll", this.scrollListener);
    },

    destroyed() {
      window.removeEventListener("scroll", this.scrollListener);
    },

    methods: {
      scrollListener() {
        this.isVisible = window.scrollY > 300;
      },

      scrollTop() {
        window.scroll({ top: 0, behavior: "smooth" });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .bottom-right {
    cursor: pointer;
    z-index: 1;
  }

  .up {
    color: $primary-color;
    height: 60px;
    width: 36px;
    margin-bottom: 2px;
    text-align: center;

    svg {
      height: 20px;
      width: 20px;
      bottom: 0;
    }
    svg.bounce {
      transition: all 200ms ease-in-out;
      animation: scroll-up 1s ease infinite;
    }
  }

  // @media only screen and (max-device-width: 768px) {
  //   .bottom-right {
  //     position: fixed;
  //     bottom: 20px;
  //     right: 20px;
  //     cursor: pointer;

  //     z-index: 1;
  //   }

  //   .up {
  //     padding: 1rem;
  //   }
  // }
</style>
