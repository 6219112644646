import labels from "./labels";

const routes = [
  { title: labels.MENU_HOME, path: "/" },
  { title: labels.MENU_ABOUT, path: "/about-us" },
  { title: labels.MENU_EXPERTISE, path: "/services" },
  { title: labels.MENU_PROJECTS, path: "/projects" },
  { title: labels.MENU_TEAMS, path: "/team" },
  { title: labels.MENU_NEWS, path: "/blogs" },
  { title: labels.MENU_CONTACT, path: "/contact-us" },
];

export default routes;
