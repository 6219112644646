import axios from "axios";
import { labels } from "@/common";
import { Notification } from "element-ui";
import createFormData from "@/composables/useFormData";
import router from "@/router";

const state = {
  banners: null,
  bannerDetail: {},
};

const mutations = {
  SET_BANNERS: (state, banners) => {
    state.banners = banners;
  },
  SET_BANNER_DETAIL: (state, detail) => {
    state.bannerDetail = detail;
  },
};

const actions = {
  async getBannerDetail({ commit }, id) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status, data } = await axios.get(`banners/${id}`);
      if (status === 200) {
        commit("SET_BANNER_DETAIL", data.data);
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.ERROR_TITLE,
          type: "error",
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async getAllBanners({ commit }, params = { page: 1 }) {
    commit("SET_BUSY", true, { root: true });
    try {
      const { status, data } = await axios.get("/banners", { params });
      if (status === 200) {
        commit("SET_BANNERS", data);
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.ERROR_TITLE,
          type: "error",
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async createBanner({ commit }, banner) {
    commit("SET_BUSY", true, { root: true });

    try {
      const { status } = await axios.post("/banners", createFormData(banner));
      if (status === 201) {
        router.push("/admin/banners");
        Notification({
          title: labels.SUCCESS_TITLE,
          type: "success",
          message: "Banner successfully created",
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.ERROR_TITLE,
          type: "error",
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async updateBanner({ commit }, updatedBanner) {
    commit("SET_BUSY", true, { root: true });

    try {
      const { status } = await axios.post(
        `/banners/${updatedBanner.id}`,
        createFormData(updatedBanner)
      );
      if (status === 200) {
        router.push("/admin/banners");
        Notification({
          title: labels.SUCCESS_TITLE,
          type: "success",
          message: "Banner successfully updated",
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.ERROR_TITLE,
          type: "error",
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },

  async deleteBanner({ commit, dispatch }, id) {
    commit("SET_BUSY", true, { root: true });

    try {
      const { status } = await axios.delete(`/banners/${id}`);
      if (status === 200) {
        dispatch("getAllBanners");
        Notification({
          title: labels.SUCCESS_TITLE,
          type: "success",
          message: "Banner successfully deleted",
        });
      }
    } catch (err) {
      if (err.response) {
        Notification({
          title: labels.ERROR_TITLE,
          type: "error",
          message: err.response.data.message,
        });
      }
    } finally {
      commit("SET_BUSY", false, { root: true });
    }
  },
};

const getters = {
  bannerCount: (state) => state.banners !== null && state.banners.data.length,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
