<template>
  <el-container direction="vertical">
    <slot name="header"></slot>

    <el-container :class="[loggedIn ? 'admin' : 'side-header', 'main-content']">
      <slot name="sidebar"></slot>

      <router-view></router-view>
    </el-container>

    <slot name="footer"></slot>
  </el-container>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    name: "AppLayout",

    computed: {
      ...mapGetters({ loggedIn: "auth/loggedIn" }),
    },
  };
</script>

<style lang="scss">
  @import "@/assets/scss/main.scss";

  .admin {
    margin-top: $header-height;
  }

  .main-content {
    width: 100%;
  }
</style>
